import Vue from "vue";
import Router from "vue-router";

const routerOptions = [
    { path: '/', component: "HomePage" },
    { path: '/infos', component: "InfoPage" },
    { path: '/program', component: "ProgramPage" },
    { path: '/gift', component: "GiftPage"},
    { path: '/cagnotte', beforeEnter() {window.open('https://lydia-app.com/pots?id=64277-mariage-lorraine-et-kevin', '_blank')}},
    { path: '/t-kevin', beforeEnter() {location.href = 'sms:0630239459'}},
    { path: '/t-lorraine', beforeEnter() {location.href = 'sms:0632141459'}},
    { path: '/loc_pougnadoresse', beforeEnter() {
        location.href="https://www.google.com/maps/place/Mas+de+Pougnadoresse/@43.8854713,4.6339936,17z/data=!4m5!3m4!1s0x12b5c48895ab88c9:0x9ba65155527d213!8m2!3d43.8854712!4d4.63399", location.target="_blank"
    }},
    { path: '/loc_mairie_castillon', beforeEnter() {
        location.href="https://www.google.com/maps/place/Mairie+de+Castillon+du+Gard/@43.970383,4.5523873,17z/data=!3m1!4b1!4m5!3m4!1s0x12b5c9555bbd0c85:0x9fd6ed2bd018037b!8m2!3d43.9703675!4d4.5545757", location.target="_blank"
    }},
    { path: '/loc_brunch_castillon', beforeEnter() {
        location.href="https://www.google.com/maps/place/8+Chem.+Croix+de+Beno%C3%AEt,+30210+Castillon-du-Gard/@43.95897,4.549355,17z/data=!3m1!4b1!4m5!3m4!1s0x12b5c8853c493de9:0xbf20c3e960de1b38!8m2!3d43.9589701!4d4.5538397", location.target="_blank"
    }}
]

const routes = routerOptions.map(route => {
    return {
      ...route,
      component: () => import(`../pages/${route.component}.vue`)
    };
  });
  
  Vue.use(Router);
  
  export default new Router({
    base: process.env.BASE_URL,
    mode: "history",
    routes, 
    scrollBehavior() {
      // always scroll to top
      window.scrollTo(0, 0);
    },
  });