<template>
  <div id="app">
    <v-app class="defaultFont">
      <v-app-bar color="teal lighten-5" light app clipped-right>
        <v-toolbar-title class="titleFont">Kévin & Lorraine</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="!isMobile">
          <v-btn
            text
            v-for="item in menuItems"
            :key="item.title"
            :to="item.path"
            :target="item.blank"
            class="mr-2">
            <v-icon left dark>{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>

      <v-content class="my-6">
        <router-view v-bind:isMobile="data.mobile"></router-view>
      </v-content>

      <v-bottom-navigation v-if="isMobile" app shift light background-color="teal lighten-5">
        <v-btn
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
          :target="item.blank"
          :disabled="item.disabled"
          :alt="item.alt"
        >
          <span>{{ item.shortTitle }}</span>
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-app>
  </div>
</template>

<script>
export default {
  created(){
    this.data = {
      mobile: this.$vuetify.breakpoint.smAndDown
    }
  },
  data() {
    return {
      appTitle: 'Awesome App',
      drawer: false,
      menuItems: [
          { title: 'Home', shortTitle: 'Home', path: '/', target: '', icon: 'home' },
          { title: 'Infos', shortTitle: 'Infos', path: '/infos', target: '', icon: 'info' },
          { title: 'Programme', shortTitle: 'Programme', path: '/program', target: '', icon: 'view_timeline' },
          { title: 'Liste de Mariage', shortTitle: 'Liste', path: '/gift', target: '_blank', icon: 'redeem'}
      ],
      // eslint-disable-next-line
      mobile: this.isMobile 
    };
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
};
</script>

<style>
  #app {
    background: url('@/assets/bg-light.png') no-repeat center center fixed !important;
    background-size: cover;
  }
</style>
